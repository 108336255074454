import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@/app/services/user.service';
import { CustomValidators } from '@/app/utils/custom-validators';
import { concat, EMPTY, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { User } from '@/app/model/user/user';
import { TranslationPipe } from '@/app/intl/translation-pipe';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.css'],
})
export class DeleteUserModalComponent implements OnInit {
  @Output() changeMade = new EventEmitter<boolean>();

  // @ts-ignore
  private _userLocal: User;

  deleteUser = false;
  modal: NgbModalRef | undefined;
  formGroup!: UntypedFormGroup;

  users$: Observable<User[]> = EMPTY;
  userLoading = false;
  userInput$ = new Subject<string>();
  searchFailed = false;

  loading = false;

  @ViewChild('deleteusermodal') templateModal!: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private translationPipe: TranslationPipe
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      user: ['', Validators.compose([Validators.required])],
      confirm: [''],
    });

    this.loadUsers();
  }

  trackByFn(item: User): number {
    return item.id;
  }

  onSubmit(): void {
    this.loading = true;

    let promise: Promise<any>;
    if (this.deleteUser) {
      promise = this.userService.adminDeleteMemberUser(
        this._userLocal,
        this.formGroup.get('user')!.value
      );
    } else {
      promise = this.userService.adminTransferUserProjects(
        this._userLocal,
        this.formGroup.get('user')!.value
      );
    }
    promise
      .then(() => {
        this.changeMade.emit();
        this.close();
      })
      .finally(() => (this.loading = false));
  }

  open(user: User, deleteUser: boolean): void {
    this._userLocal = user;
    this.deleteUser = deleteUser;

    if (deleteUser) {
      // @ts-ignore
      this.formGroup
        .get('confirm')
        .setValidators(
          Validators.compose([
            Validators.required,
            CustomValidators.equals(
              this.translationPipe.transform('interface_delete')
            ),
          ])
        );
    }

    this.modal = this.modalService.open(this.templateModal);
  }

  close(): void {
    this.resetFields();
    this.modal?.close();
  }

  resetFields(): void {
    this.formGroup.reset();
  }

  private loadUsers() {
    this.users$ = concat(
      of([]), // default items
      this.userInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.userLoading = true)),
        switchMap((term) =>
          this.userService.searchUsersByName(term).pipe(
            map((users) =>
              users.filter(
                (user) =>
                  user.id != this._userLocal.id &&
                  user.company.id == this._userLocal.company.id
              )
            ),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          )
        ),
        tap(() => (this.userLoading = false))
      )
    );
  }
}
