<ng-template #deleteusermodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">
        {{
          deleteUser
            ? ("interface_delete_user" | translationPipe)
            : "Transfer projects"
        }}
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form
      [formGroup]="formGroup"
      id="deleteUserForm"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <div class="form-group">
        <label for="user">{{
          deleteUser
            ? ("interface_transfer_user_project_to" | translationPipe)
            : "Ownership of all this user's projects will be transferred to:"
        }}</label>
        <ng-select
          [items]="$any(users$ | async)"
          bindLabel="fullnameWithCompanyName"
          [hideSelected]="true"
          [loading]="userLoading"
          [trackByFn]="trackByFn"
          formControlName="user"
          [typeahead]="userInput$"
        >
        </ng-select>
        <div class="form-invalid-message" *ngIf="searchFailed">
          {{ "interface_fetch_users_failed" | translationPipe }}
        </div>
        <validation-messages
          *ngIf="!searchFailed"
          [control]="formGroup.get('user')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>
      <b *ngIf="!deleteUser">
        The user will be kept as collaborator on each of their projects after
        the transfer.
      </b>
      <div *ngIf="deleteUser" class="form-group">
        <label for="confirm"
          >{{ "interface_confirm_delete_user" | translationPipe }}
        </label>
        <input
          id="confirm"
          class="form-control"
          name="confirm"
          type="text"
          formControlName="confirm"
        />
        <validation-messages
          [control]="formGroup.get('confirm')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          {{ "interface_cancel" | translationPipe }}
        </button>
        <button
          type="submit"
          class="btn btn-primary login-btn"
          [disabled]="loading || !formGroup.valid"
        >
          <span *ngIf="!loading"
            >{{
              deleteUser ? ("interface_delete" | translationPipe) : "Transfer"
            }}
          </span>
          <spinner *ngIf="loading"></spinner>
        </button>
      </div>
    </form>
  </div>
</ng-template>
