import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class ScenarioApiService {
  constructor(private http: HttpClient) {}

  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'scenarios/' + endPoint;
  }

  async createScenario(projectId: number): Promise<Scenario> {
    const url = this.buildPath(projectId.toString());
    const response = await this.http
      .post<Scenario>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Scenario, response);
  }

  async getScenarioFromDB(scenarioId: number): Promise<Scenario> {
    const url = this.buildPath(scenarioId.toString());
    const response = await this.http
      .get<Scenario>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Scenario, response);
  }

  async updateScenario(
    scenarioId: number,
    scenario: Scenario
  ): Promise<Scenario> {
    const url = this.buildPath(scenarioId.toString());
    const response = await this.http
      .put<Scenario>(url, scenario, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Scenario, response);
  }

  async updateScenarioHasSecondaryPackaging(
    scenarioId: number,
    hasSecondaryPackaging: boolean
  ): Promise<Scenario> {
    const url = this.buildPath(
      'update-has-secondary-packaging/' + scenarioId.toString()
    );
    const response = await this.http
      .put<Scenario>(url, hasSecondaryPackaging, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Scenario, response);
  }

  async updateScenarioHasTertiaryPackaging(
    scenarioId: number,
    hasTertiaryPackaging: boolean
  ): Promise<Scenario> {
    const url = this.buildPath(
      'update-has-tertiary-packaging/' + scenarioId.toString()
    );
    const response = await this.http
      .put<Scenario>(url, hasTertiaryPackaging, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Scenario, response);
  }

  async deleteScenario(scenarioId: number): Promise<void> {
    const url = this.buildPath(scenarioId.toString());
    await this.http.delete<Scenario>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  async duplicateScenario(
    scenarioId: number,
    newName: string
  ): Promise<Scenario> {
    const url = this.buildPath(scenarioId.toString() + '/duplicate');
    const response = await this.http
      .post<Scenario>(url, { newName }, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Scenario, response);
  }

  async copyFromLibrary(publishedScenarioId: number, projectId: number) {
    const url = this.buildPath(
      'copy-from-lib/' + publishedScenarioId.toString()
    );
    const response = await this.http
      .post<Scenario>(url, projectId, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Scenario, response);
  }
}
