import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserApiService } from '../api/user-api.service';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting, SortingDirection } from '@/app/model/search/sorting';
import { Page } from '@/app/model/pagination/page';
import { User } from '@/app/model/user/user';
import { map } from 'rxjs/operators';
import { UserComplete } from '@/app/model/user/user-complete';
import { DownloadService } from './download-service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private userApiService: UserApiService) {}

  getUsers(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting
  ): Promise<Page<User>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.userApiService
      .getUsers(page, filterCriteria, sorting)
      .toPromise();
  }

  searchUsersByName(search: string): Observable<Array<User>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      this.userApiService
        .getUsers(
          0,
          [new FilterCriterion('search', search)],
          new Sorting(SortingDirection.ASC, 'firstname')
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        .pipe(map((response) => response.content))
    );
  }

  adminGetUserComplete(userId: number): Promise<UserComplete> {
    return this.userApiService.adminGetUserComplete(userId);
  }

  adminResendInvitation(userId: number): Promise<void> {
    return this.userApiService.adminResendInvitation(userId);
  }

  adminCreateMemberUser(user: User): Promise<User> {
    return this.userApiService.adminCreateMemberUser(user);
  }

  adminEditMemberUser(user: User): Promise<User> {
    return this.userApiService.adminEditMemberUser(user);
  }

  adminDeleteMemberUser(deletedUser: User, newOwner: User): Promise<any> {
    return this.userApiService.adminDeleteMemberUser(deletedUser, newOwner);
  }

  adminTransferUserProjects(oldOwner: User, newOwner: User): Promise<any> {
    return this.userApiService.adminTransferProjects(oldOwner.id, newOwner.id);
  }

  async superAdminGetMemberUserStatistics(
    localeId: number | undefined
  ): Promise<void> {
    const { filename, content } =
      await this.userApiService.superAdminGetMemberUserStatistics(
        localeId === undefined ? 0 : localeId
      );
    DownloadService.download(filename, content);
  }

  createGuestUserAccount(guestuserCreationData: any): Promise<any> {
    return this.userApiService.createGuestUser(guestuserCreationData);
  }

  confirmSignUp(username: string, code: string): Promise<any> {
    return this.userApiService.confirmSignUp(username, code);
  }

  forgotPassword(email: string): Promise<any> {
    return this.userApiService.forgotPassword(email);
  }

  forgotPasswordSubmit(
    email: string,
    code: string,
    password: string
  ): Promise<any> {
    return this.userApiService.forgotPasswordSubmit(email, code, password);
  }
}
