import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@/app/model/user/user';
import { ApiUtils } from '../api-utils';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'users/' + endPoint;
  }

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Promise<CognitoUser | any> {
    return Auth.signIn(username, password);
  }

  completeNewPassword(
    cognitoUser: CognitoUser,
    password: string,
    requiredAttributes: any
  ): Promise<CognitoUser | any> {
    return Auth.completeNewPassword(cognitoUser, password, requiredAttributes);
  }

  logout(): Promise<any> {
    return Auth.signOut();
  }

  getCurrentUserOnIdentityProvider(): Promise<CognitoUser | any> {
    return Auth.currentAuthenticatedUser();
  }

  getCurrentUserOnRessoureServer(): Promise<User | any> {
    const url = this.buildPath('me');
    return this.http.get<User>(url, ApiUtils.HTTP_OPTIONS).toPromise<User>();
  }
}
