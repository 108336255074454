/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { EmailFormModalComponent } from 'src/app/pages/email-form-modal/email-form-modal.component';

@Component({
  selector: 'app-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.css'],
})
export class LoginBoxComponent implements OnInit {
  loginForm!: UntypedFormGroup;
  newPasswordForm!: UntypedFormGroup;

  errorMessage: string | null = '';
  loading = false;

  user: any;

  Challenge = Challenge;
  challenge: Challenge = Challenge.PASSWORD_VERIFIER;

  @ViewChild(EmailFormModalComponent)
  private emailFormModalComponent!: EmailFormModalComponent;

  constructor(
    private _loginService: LoginService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });

    this.newPasswordForm = new UntypedFormGroup({});
  }

  onSubmitNewPasswordRequired(password: string): void {
    this.errorMessage = null;
    this.loading = true;
    this._loginService
      .completeNewPassword(this.user, password)
      .catch((err) => {
        this.errorMessage = err.message;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onSubmit(customerData: any): Promise<void> {
    try {
      this.errorMessage = null;
      this.loading = true;

      this.user = await this._loginService.login(
        customerData.email,
        customerData.password
      );

      if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.challenge = Challenge.NEW_PASSWORD_REQUIRED;
      } else {
        this.loginForm.reset();
      }
    } catch (err: any) {
      switch (err.code) {
        case 'NotAuthorizedException':
          this.errorMessage = 'interface_login_error_not_authorized_exception';
          break;
        case 'UserNotFoundException':
          this.errorMessage = 'interface_login_error_not_found_exception';
          break;
        default:
          this.errorMessage = err.message;
          break;
      }
    } finally {
      this.loading = false;
    }
  }

  formInitialized(name: string, form: UntypedFormGroup): void {
    this.newPasswordForm.setControl(name, form);
  }

  forgottenPw(): void {
    this.emailFormModalComponent.open();
  }

  confirmForgottenPw(): void {
    this.emailFormModalComponent.close();
  }

  isChallenge(challenge: Challenge): boolean {
    return this.challenge == challenge;
  }
}

enum Challenge {
  PASSWORD_VERIFIER,
  NEW_PASSWORD_REQUIRED,
}
