import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { awsconfig, environment } from './environments/environment';
import { git } from './environments/git-info';

import * as Sentry from '@sentry/angular-ivy';

import { getXhrResponse } from '@/app/utils/xhr-utils';
import { sendAttachments } from '@/app/utils/sentry-attachment-utils';
import {
  HttpBreadcrumbData,
  isXhrBreadcrumb,
} from '@/app/utils/sentry-breadcrumb-utils';
import { Event, EventHint } from '@sentry/types';
import { Amplify } from 'aws-amplify';

Amplify.configure(awsconfig);

let lastXhr:
  | (HttpBreadcrumbData & {
      requestBody: any;
      responseBody: any;
    })
  | undefined = undefined;

Sentry.init({
  dsn: 'https://72b02c091ecf429180012f795b3d3e03@o461389.ingest.sentry.io/5463263',
  environment: environment.name,
  enabled: environment.enableSentry,
  release: git.commit.id,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  normalizeDepth: Number.MAX_SAFE_INTEGER, // normalize, but don't truncate based on depth
  beforeBreadcrumb(breadcrumb, hint) {
    // capture last XHR using Sentry's breadcrumbs
    if (isXhrBreadcrumb(breadcrumb))
      lastXhr = {
        ...breadcrumb.data,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        requestBody: hint?.input,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        responseBody: getXhrResponse(hint?.xhr),
      };
    return breadcrumb;
  },
  tracesSampleRate: 0.5,
});

Sentry.addGlobalEventProcessor((event: Event, eventHint?: EventHint) => {
  try {
    if (lastXhr !== undefined)
      sendAttachments(event, [
        {
          filename: 'last-xhr.json',
          content: JSON.stringify(lastXhr),
          contentType: 'application/json',
        },
      ]);
    return event;
  } catch (ex) {
    console.error(ex);
    return null;
  }
});

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
