import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SingleUseProject } from 'src/app/model/packaging-model/project/single-use-project';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { GlobalService } from '@/app/services/global.service';
import { Country } from '@/app/model/data/country';
import { FormUtils } from '@/app/utils/form-utils';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { ComponentLevel } from '@/app/model/packaging-model/component/component-level.enum';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PrimaryPackaging } from '@/app/model/packaging-model/packaging/primary-packaging';
import { SecondaryPackaging } from '@/app/model/packaging-model/packaging/secondary-packaging';
import { TertiaryPackagingBox } from '@/app/model/packaging-model/packaging/tertiary-packaging-box';
import { TertiaryPackagingPalletization } from '@/app/model/packaging-model/packaging/tertiary-packaging-palletization';
import { PkgComponentService } from '@/app/services/pkg-component.service';
import { GuestService } from '@/app/services/guest.service';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import assert from 'assert';
import { CustomValidators } from '@/app/utils/custom-validators';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css'],
})
export class GeneralInfoComponent implements OnInit, OnDestroy {
  readonly ComponentLevel = ComponentLevel;

  @ViewChildren(ConfirmModalComponent)
  private readonly confirmModals!: QueryList<ConfirmModalComponent>;

  componentToBeDeleted: PkgComponent | undefined;

  displayComponent = false;
  displayCountryInfo = false;

  private _scenarioform: UntypedFormGroup | undefined;

  protected readonly boundaries = {
    scenarioName: {
      max: 255,
    },
    boxes: {
      min: 1,
      max: 300_000,
    },
  };

  get scenarioform(): UntypedFormGroup {
    assert(this._scenarioform !== undefined);
    return this._scenarioform;
  }

  set scenarioform(scenarioform: UntypedFormGroup) {
    this._scenarioform = scenarioform;
  }

  private _primaryPackform: UntypedFormGroup | undefined;

  get primaryPackform(): UntypedFormGroup {
    assert(this._primaryPackform !== undefined);
    return this._primaryPackform;
  }

  set primaryPackform(primaryPackform: UntypedFormGroup) {
    this._primaryPackform = primaryPackform;
  }

  private _secondaryPackform: UntypedFormGroup | undefined;

  get secondaryPackform(): UntypedFormGroup {
    assert(this._secondaryPackform !== undefined);
    return this._secondaryPackform;
  }

  set secondaryPackform(secondaryPackform: UntypedFormGroup) {
    this._secondaryPackform = secondaryPackform;
  }

  private _tertiaryBoxPackform: UntypedFormGroup | undefined;

  get tertiaryBoxPackform(): UntypedFormGroup {
    assert(this._tertiaryBoxPackform !== undefined);
    return this._tertiaryBoxPackform;
  }

  set tertiaryBoxPackform(tertiaryBoxPackform: UntypedFormGroup) {
    this._tertiaryBoxPackform = tertiaryBoxPackform;
  }

  private _tertiaryPalletizationPackform: UntypedFormGroup | undefined;

  get tertiaryPalletizationPackform(): UntypedFormGroup {
    assert(this._tertiaryPalletizationPackform !== undefined);
    return this._tertiaryPalletizationPackform;
  }

  set tertiaryPalletizationPackform(
    tertiaryPalletizationPackform: UntypedFormGroup
  ) {
    this._tertiaryPalletizationPackform = tertiaryPalletizationPackform;
  }

  private _forms: Array<UntypedFormGroup> | undefined;

  get forms(): Array<UntypedFormGroup> {
    assert(this._forms !== undefined);
    return this._forms;
  }

  set forms(forms: Array<UntypedFormGroup>) {
    this._forms = forms;
  }

  get project(): SingleUseProject {
    if (this.guestService.isUserGuest() && this.guestService.guestUserProject) {
      return this.guestService.guestUserProject;
    } else return this.projectService.currentSingleUseProject;
  }

  isDefaultSelected = false;
  isSpecifySelected = false;
  isNoTertiarySelected = false;

  private _scenarioOriginal: Scenario | undefined;

  get scenarioOriginal(): Scenario {
    assert(this._scenarioOriginal !== undefined);
    return this._scenarioOriginal;
  }

  set scenarioOriginal(scenarioOriginal: Scenario) {
    this._scenarioOriginal = scenarioOriginal;
  }

  private _scenarioLocal: Scenario | undefined;

  get scenarioLocal(): Scenario {
    assert(this._scenarioLocal !== undefined);
    return this._scenarioLocal;
  }

  set scenarioLocal(scenarioLocal: Scenario) {
    this._scenarioLocal = scenarioLocal;
  }

  private primaryPackOriginal: PrimaryPackaging | undefined;
  private secondaryPackOriginal: SecondaryPackaging | undefined;
  private tertiaryBoxPackOriginal: TertiaryPackagingBox | undefined;
  private tertiaryPalletPackOriginal:
    | TertiaryPackagingPalletization
    | undefined;
  private primaryPackLocal: PrimaryPackaging | undefined;
  private secondaryPackLocal: SecondaryPackaging | undefined;
  private tertiaryBoxPackLocal: TertiaryPackagingBox | undefined;
  private tertiaryPalletPackLocal: TertiaryPackagingPalletization | undefined;

  get countries(): Array<Country> | undefined {
    return this.globalService.countries;
  }

  get isReadOnly(): boolean {
    return this.projectService.isReadOnly;
  }

  private saveTimer: NodeJS.Timer | undefined;

  private scenarioId: number | undefined;

  @HostListener('window:beforeunload', ['$event'])
  private saveAllExceptPackagingIfChanged(): void {
    if (
      this._scenarioLocal !== undefined &&
      this.scenarioLocal.editableFieldsHaveChanged(this.scenarioOriginal)
    ) {
      this.projectService
        .updateScenario(this.scenarioLocal, this.scenarioOriginal)
        .catch((err) => console.error(err));
    }

    if (
      this.primaryPackLocal !== undefined &&
      this.primaryPackOriginal !== undefined &&
      this.primaryPackLocal.editableFieldsHaveChanged(this.primaryPackOriginal)
    ) {
      this.projectService
        .updatePrimaryPackaging(this.primaryPackLocal, this.primaryPackOriginal)
        .catch((err) => console.error(err));
    }

    if (
      this._scenarioOriginal?.firstSecondaryPackaging !== undefined &&
      this.secondaryPackOriginal !== undefined &&
      this.secondaryPackLocal !== undefined &&
      this.secondaryPackLocal.editableFieldsHaveChanged(
        this.secondaryPackOriginal
      )
    ) {
      this.projectService
        .updateSecondaryPackaging(
          this.secondaryPackLocal,
          this.secondaryPackOriginal
        )
        .catch((err) => console.error(err));
    }

    if (
      this.secondaryPackOriginal?.associationWithPrimary !== undefined &&
      this.secondaryPackLocal?.associationWithPrimary !== undefined &&
      this.secondaryPackLocal.associationWithPrimary.editableFieldsHaveChanged(
        this.secondaryPackOriginal.associationWithPrimary
      )
    ) {
      this.projectService
        .updatePackagingAssociation(
          this.secondaryPackLocal.associationWithPrimary,
          this.secondaryPackOriginal.associationWithPrimary
        )
        .catch((err) => console.error(err));
    }

    if (
      this.tertiaryBoxPackOriginal?.associationWithConsumerPack !== undefined &&
      this.tertiaryBoxPackLocal?.associationWithConsumerPack !== undefined &&
      this.tertiaryBoxPackLocal.associationWithConsumerPack.editableFieldsHaveChanged(
        this.tertiaryBoxPackOriginal.associationWithConsumerPack
      )
    ) {
      this.projectService
        .updatePackagingAssociation(
          this.tertiaryBoxPackLocal.associationWithConsumerPack,
          this.tertiaryBoxPackOriginal.associationWithConsumerPack
        )
        .catch((err) => console.error(err));
    }

    if (
      this.tertiaryPalletPackOriginal?.associationWithTertiaryBox !==
        undefined &&
      this.tertiaryPalletPackLocal?.associationWithTertiaryBox !== undefined &&
      this.tertiaryPalletPackLocal?.associationWithTertiaryBox.editableFieldsHaveChanged(
        this.tertiaryPalletPackOriginal.associationWithTertiaryBox
      )
    ) {
      this.projectService
        .updatePackagingAssociation(
          this.tertiaryPalletPackLocal.associationWithTertiaryBox,
          this.tertiaryPalletPackOriginal.associationWithTertiaryBox
        )
        .catch((err) => console.error(err));
    }
  }

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly projectService: ProjectService,
    private readonly pkgComponentService: PkgComponentService,
    private readonly formBuilder: UntypedFormBuilder,
    public readonly globalService: GlobalService,
    public readonly guestService: GuestService
  ) {}

  ngOnInit(): void {
    this.startSaveTimer();
    this.listenUrlChange();
  }

  private listenUrlChange() {
    this.route.params.subscribe({
      next: (params: Params) => {
        this.scenarioId = parseFloat(params['id']);
        this.initOrUpdateAllForms();
      },
      error: (err) => console.error(err),
    });
  }

  initOrUpdateAllForms(): void {
    assert(this.scenarioId !== undefined);
    this.projectService.selectScenario(this.scenarioId);
    this.saveAllExceptPackagingIfChanged();
    this.fillLocalVariables();

    if (this._scenarioform === undefined) {
      this.initScenarioForm();
    } else {
      this.updateFieldsScenario();
    }

    if (this._primaryPackform === undefined) {
      this.initPrimaryPackForm();
    } else {
      this.updateFieldsPrimaryPackaging();
    }

    if (this._secondaryPackform === undefined) {
      this.initSecondaryPackForm();
    } else {
      this.updateFieldsSecondaryPackaging();
    }

    if (this._tertiaryBoxPackform === undefined) {
      this.initTertiaryBoxPackForm();
    } else {
      this.updateFieldsTertiaryPackaging();
    }

    if (this._tertiaryPalletizationPackform === undefined) {
      this.initTertiaryPalletPackForm();
    } else {
      this.updateFieldsTertiaryPalletPackaging();
    }

    this.forms = [
      this.scenarioform,
      this.primaryPackform,
      this.secondaryPackform,
      this.tertiaryBoxPackform,
      this.tertiaryPalletizationPackform,
    ];

    this.updateReadOnlyForm();
  }

  private updateReadOnlyForm() {
    this.forms.forEach((form: UntypedFormGroup) => {
      if (this.isReadOnly) {
        form.disable();
      } else {
        form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.stopSaveTimer();
    this.saveAllExceptPackagingIfChanged();
  }

  private fillLocalVariables(): void {
    this.scenarioOriginal = this.projectService.selectedScenario;
    this.scenarioLocal = this.scenarioOriginal.copy();
    this.fillPackagingLocalVariables();
  }

  private fillPackagingLocalVariables(): void {
    if (this.scenarioOriginal.firstPrimaryPackaging !== undefined) {
      this.primaryPackOriginal = this.scenarioOriginal.firstPrimaryPackaging;
      this.primaryPackLocal =
        this.scenarioOriginal.firstPrimaryPackaging.copy();
    }
    if (this.scenarioOriginal.firstSecondaryPackaging !== undefined) {
      this.secondaryPackOriginal =
        this.scenarioOriginal.firstSecondaryPackaging;
      this.secondaryPackLocal =
        this.scenarioOriginal.firstSecondaryPackaging.copy();
    }
    if (this.scenarioOriginal.firstTertiaryPackagingBox !== undefined) {
      this.tertiaryBoxPackOriginal =
        this.scenarioOriginal.firstTertiaryPackagingBox;
      this.tertiaryBoxPackLocal =
        this.scenarioOriginal.firstTertiaryPackagingBox.copy();
    }
    if (
      this.scenarioOriginal.firstTertiaryPackagingPalletization !== undefined
    ) {
      this.tertiaryPalletPackOriginal =
        this.scenarioOriginal.firstTertiaryPackagingPalletization;
      this.tertiaryPalletPackLocal =
        this.scenarioOriginal.firstTertiaryPackagingPalletization.copy();
    }

    this.isDefaultSelected = !this.scenarioLocal.specifyTertiaryPackaging;
    this.isNoTertiarySelected = this.isNoTertiary();
    this.isSpecifySelected =
      !this.isNoTertiarySelected && !this.isDefaultSelected;
    if (this.isDefaultSelected || this.isNoTertiarySelected)
      this.projectService.noTertiary = true;
  }

  private isNoTertiary(): boolean {
    return (
      this.scenarioLocal.specifyTertiaryPackaging &&
      this.tertiaryBoxPackOriginal != undefined &&
      this.tertiaryPalletPackOriginal != undefined &&
      this.tertiaryBoxPackOriginal.components.length == 0 &&
      this.tertiaryPalletPackOriginal.components.length == 0 &&
      this.tertiaryBoxPackOriginal.associationWithConsumerPack
        ?.numberOfContentPerContainer == 1 &&
      this.tertiaryPalletPackOriginal.associationWithTertiaryBox
        ?.numberOfContentPerContainer == 1
    );
  }

  private startSaveTimer() {
    if (this.saveTimer === undefined)
      this.saveTimer = setInterval(
        (_) => this.saveAllExceptPackagingIfChanged(),
        5000
      );
  }

  private stopSaveTimer(): void {
    if (this.saveTimer !== undefined) {
      clearInterval(this.saveTimer);
      this.saveTimer = undefined;
    }
  }

  private initScenarioForm(): void {
    this.scenarioform = this.formBuilder.group({
      name: [
        this.scenarioLocal.name,
        Validators.compose([
          Validators.required,
          Validators.maxLength(this.boundaries.scenarioName.max),
        ]),
      ],
      productionCountry: [
        this.scenarioLocal.productionCountry.label,
        Validators.required,
      ],
      salesCountry: [
        this.scenarioLocal.salesCountry.label,
        Validators.required,
      ],
    });
    FormUtils.updateModelFromFieldValue<string>(
      this.scenarioform.controls['name'],
      (v) => (this.scenarioLocal.name = v)
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.scenarioform.controls['productionCountry'],
      (v) => {
        const productionCountry = this.countries?.find((s) => s.label == v);
        if (productionCountry !== undefined)
          this.scenarioLocal.productionCountry = productionCountry;
      }
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.scenarioform.controls['salesCountry'],
      (v) => {
        const salesCountry = this.countries?.find((s) => s.label == v);
        if (salesCountry !== undefined)
          this.scenarioLocal.salesCountry = salesCountry;
      }
    );
  }

  private initPrimaryPackForm(): void {
    assert(this.primaryPackLocal !== undefined);
    this.primaryPackform = this.formBuilder.group({
      claimedVolume: [
        this.primaryPackLocal.claimedVolume,
        Validators.compose([
          Validators.required,
          CustomValidators.nonZeroDecimalValidator.compose,
        ]),
      ],
      rateOfRestitution: [
        this.primaryPackLocal.rateOfRestitution * 100,
        Validators.compose([
          Validators.required,
          CustomValidators.percentageNonZeroValidator.compose,
        ]),
      ],
      primaryPackagingIsRecyclable: [
        this.primaryPackLocal.isRecyclable,
        Validators.required,
      ],
    });
    FormUtils.updateModelFromFieldValue<string>(
      this.primaryPackform.controls['claimedVolume'],
      (v) => {
        assert(this.primaryPackLocal !== undefined);
        this.primaryPackLocal.claimedVolume = parseFloat(v);
      }
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.primaryPackform.controls['rateOfRestitution'],
      (v) => {
        assert(this.primaryPackLocal !== undefined);
        this.primaryPackLocal.rateOfRestitution = parseFloat(v) / 100;
      }
    );
    FormUtils.updateModelFromFieldValue<boolean>(
      this.primaryPackform.controls['primaryPackagingIsRecyclable'],
      (v) => {
        assert(this.primaryPackLocal !== undefined);
        this.primaryPackLocal.isRecyclable = v;
      }
    );
  }

  private initSecondaryPackForm(): void {
    this.secondaryPackform = this.formBuilder.group({
      // directly use scenarioOriginal as it is a switch and we directly call the api
      hasSecondaryPackaging:
        this.scenarioOriginal.firstSecondaryPackaging !== undefined
          ? [this.scenarioOriginal.hasSecondaryPackaging, Validators.required]
          : '',
      secondaryPackagingIsRecyclable:
        this.scenarioOriginal.firstSecondaryPackaging !== undefined
          ? [this.secondaryPackLocal?.isRecyclable, Validators.required]
          : '',
      numberOfPrimaryPerSecondaryPackaging:
        this.scenarioOriginal.firstSecondaryPackaging !== undefined
          ? [
              this.secondaryPackLocal?.associationWithPrimary
                ?.numberOfContentPerContainer,
              Validators.compose([Validators.required, Validators.min(1)]),
            ]
          : '',
    });

    if (this.scenarioOriginal.firstSecondaryPackaging !== undefined) {
      FormUtils.updateModelFromFieldValue<boolean>(
        this.secondaryPackform.controls['secondaryPackagingIsRecyclable'],
        (v) => {
          if (this.secondaryPackLocal != undefined) {
            this.secondaryPackLocal.isRecyclable = v;
          }
        }
      );
      FormUtils.updateModelFromFieldValue<number>(
        this.secondaryPackform.controls['numberOfPrimaryPerSecondaryPackaging'],
        (v) => {
          if (this.secondaryPackLocal != undefined) {
            this.secondaryPackLocal.containerPackagingAssociations[0].numberOfContentPerContainer =
              v;
          }
        }
      );
    }
  }

  private initTertiaryBoxPackForm(): void {
    this.tertiaryBoxPackform = this.formBuilder.group({
      // directly use scenarioOriginal as it is a switch and we directly call the api
      specifyTertiaryPackaging:
        this.scenarioOriginal.firstTertiaryPackagingBox !== undefined
          ? [
              this.scenarioOriginal.specifyTertiaryPackaging,
              Validators.required,
            ]
          : '',
      numberOfSecondaryPerTertiaryBox:
        this.scenarioOriginal.firstTertiaryPackagingBox !== undefined
          ? [
              this.tertiaryBoxPackLocal?.associationWithConsumerPack
                ?.numberOfContentPerContainer,
              Validators.compose([
                Validators.required,
                Validators.min(this.boundaries.boxes.min),
                Validators.max(this.boundaries.boxes.max),
              ]),
            ]
          : '',
    });
    if (this.scenarioOriginal.firstTertiaryPackagingBox !== undefined) {
      FormUtils.updateModelFromFieldValue<string>(
        this.tertiaryBoxPackform.controls['numberOfSecondaryPerTertiaryBox'],
        (v) => {
          if (
            this.tertiaryBoxPackLocal !== undefined &&
            this.tertiaryBoxPackLocal.associationWithConsumerPack !== undefined
          )
            this.tertiaryBoxPackLocal.associationWithConsumerPack.numberOfContentPerContainer =
              parseFloat(v);
        }
      );
    }
  }

  private initTertiaryPalletPackForm(): void {
    this.tertiaryPalletizationPackform = this.formBuilder.group({
      // directly use scenarioOriginal as it is a switch and we directly call the api
      specifyTertiaryPackagingPallet:
        this.scenarioOriginal.firstTertiaryPackagingPalletization !== undefined
          ? [
              this.scenarioOriginal.specifyTertiaryPackagingPallet,
              Validators.required,
            ]
          : '',
      numberOfTertiaryBoxPerPallet:
        this.scenarioOriginal.firstTertiaryPackagingPalletization !== undefined
          ? [
              this.tertiaryPalletPackLocal?.associationWithTertiaryBox
                ?.numberOfContentPerContainer,
              Validators.compose([
                Validators.required,
                Validators.min(this.boundaries.boxes.min),
                Validators.max(this.boundaries.boxes.max),
              ]),
            ]
          : '',
    });
    if (
      this.scenarioOriginal.firstTertiaryPackagingPalletization !== undefined
    ) {
      FormUtils.updateModelFromFieldValue<string>(
        this.tertiaryPalletizationPackform.controls[
          'numberOfTertiaryBoxPerPallet'
        ],
        (v) => {
          if (
            this.tertiaryPalletPackLocal !== undefined &&
            this.tertiaryPalletPackLocal.associationWithTertiaryBox !==
              undefined
          )
            this.tertiaryPalletPackLocal.associationWithTertiaryBox.numberOfContentPerContainer =
              parseFloat(v);
        }
      );
    }
  }

  private goToScenario(scenarioId: number): void {
    void this.router.navigate([`../../${scenarioId}/general-info`], {
      relativeTo: this.route,
    });
  }

  setToReference(): void {
    this.confirmModals
      .filter((item) => item.id == 'reference-scenario')[0]
      .open();
  }

  async confirmOrAbortReferencingScenario(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'reference-scenario')[0]
      .close();
    if (answer) {
      await this.projectService.setScenarioToReference(this.scenarioLocal.id);
    }
  }

  deleteScenario(): void {
    this.confirmModals
      .filter((item) => item.id == 'gen-delete-scenario')[0]
      .open();
  }

  async confirmOrAbortScenarioDeletion(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'gen-delete-scenario')[0]
      .close();
    if (answer) {
      await this.projectService.deleteScenario(this.scenarioLocal);
      this.goToScenario(this.project.referenceScenarioId);
    }
  }

  async duplicateScenario(): Promise<void> {
    const duplicatedScenarioId: number =
      await this.projectService.duplicateScenario(this.scenarioLocal);
    this.goToScenario(duplicatedScenarioId);
  }

  private async saveHasSecondaryPackaging(
    hasSecondaryPackaging: boolean
  ): Promise<void> {
    if (hasSecondaryPackaging != this.scenarioOriginal.hasSecondaryPackaging) {
      this.scenarioOriginal =
        await this.projectService.updateScenarioHasSecondaryPackaging(
          hasSecondaryPackaging,
          this.scenarioOriginal
        );
      this.fillPackagingLocalVariables();
      if (this.scenarioOriginal.firstSecondaryPackaging !== undefined) {
        this.secondaryPackOriginal =
          this.scenarioOriginal.firstSecondaryPackaging;
        this.secondaryPackLocal = this.secondaryPackOriginal.copy();
      }
      this.initSecondaryPackForm();
      this.initTertiaryBoxPackForm();
      this.updateFieldsSecondaryPackaging();
      this.updateFieldsTertiaryPackaging();
      this.updateFieldsTertiaryPalletPackaging();
    }
  }

  private async saveHasTertiaryPackaging(
    specifyTertiaryPackaging: boolean
  ): Promise<void> {
    if (
      specifyTertiaryPackaging != this.scenarioOriginal.specifyTertiaryPackaging
    ) {
      await this.projectService.updateScenarioHasTertiaryPackaging(
        specifyTertiaryPackaging,
        this.scenarioOriginal
      );
      await this.projectService.updateScenarioHasTertiaryPackagingPalletization(
        specifyTertiaryPackaging,
        this.scenarioOriginal
      );
      if (this.scenarioOriginal.firstTertiaryPackagingBox) {
        this.tertiaryBoxPackOriginal =
          this.scenarioOriginal.firstTertiaryPackagingBox;
        this.tertiaryBoxPackLocal = this.tertiaryBoxPackOriginal.copy();
        this.tertiaryPalletPackOriginal =
          this.scenarioOriginal.firstTertiaryPackagingPalletization;
        this.tertiaryPalletPackLocal = this.tertiaryPalletPackOriginal?.copy();
      }
      this.updateFieldsTertiaryPackaging();
      this.updateFieldsTertiaryPalletPackaging();
      this.initTertiaryBoxPackForm();
      this.initTertiaryPalletPackForm();
    }
  }

  private updateFieldsScenario(): void {
    this.scenarioform.controls['name'].setValue(this.scenarioLocal.name);
    this.scenarioform.controls['productionCountry'].setValue(
      this.scenarioLocal.productionCountry.label
    );
    this.scenarioform.controls['salesCountry'].setValue(
      this.scenarioLocal.salesCountry.label
    );
  }

  private updateFieldsPrimaryPackaging(): void {
    assert(this.primaryPackLocal !== undefined);
    this.primaryPackform.controls['claimedVolume'].setValue(
      this.primaryPackLocal.claimedVolume
    );
    this.primaryPackform.controls['rateOfRestitution'].setValue(
      this.primaryPackLocal.rateOfRestitution * 100
    );
    this.primaryPackform.controls['primaryPackagingIsRecyclable'].setValue(
      this.primaryPackLocal.isRecyclable
    );
  }

  private updateFieldsSecondaryPackaging(): void {
    // directly use scenarioOriginal as it is a switch and we directly call the api
    this.secondaryPackform.controls['hasSecondaryPackaging'].setValue(
      this.scenarioOriginal.hasSecondaryPackaging
    );
    if (this.scenarioOriginal.firstSecondaryPackaging !== undefined) {
      this.secondaryPackform.controls[
        'secondaryPackagingIsRecyclable'
      ].setValue(this.secondaryPackLocal?.isRecyclable);
      this.secondaryPackform.controls[
        'numberOfPrimaryPerSecondaryPackaging'
      ].setValue(
        this.scenarioOriginal.firstSecondaryPackaging.associationWithPrimary
          ?.numberOfContentPerContainer
      );
    }
  }

  private updateFieldsTertiaryPackaging(): void {
    // directly use scenarioOriginal as it is a switch and we directly call the api
    this.tertiaryBoxPackform.controls['specifyTertiaryPackaging'].setValue(
      this.scenarioOriginal.specifyTertiaryPackaging
    );
    if (this.scenarioOriginal.firstTertiaryPackagingBox !== undefined) {
      this.tertiaryBoxPackform.controls[
        'numberOfSecondaryPerTertiaryBox'
      ].setValue(
        this.tertiaryBoxPackLocal?.associationWithConsumerPack
          ?.numberOfContentPerContainer
      );
    }
  }

  private updateFieldsTertiaryPalletPackaging(): void {
    if (
      this.scenarioOriginal.firstTertiaryPackagingPalletization !== undefined
    ) {
      this.tertiaryPalletizationPackform.controls[
        'numberOfTertiaryBoxPerPallet'
      ].setValue(
        this.tertiaryPalletPackLocal?.associationWithTertiaryBox
          ?.numberOfContentPerContainer
      );
    }
  }

  async selectTab(chosenTab: 'default' | 'specify' | 'noTertiary') {
    this.resetTabs();
    switch (chosenTab) {
      case 'default':
        this.isDefaultSelected = true;
        this.projectService.noTertiary = true;
        await this.saveHasTertiaryPackaging(false);
        break;
      case 'specify':
        this.isSpecifySelected = true;
        await this.saveHasTertiaryPackaging(true);
        break;
      case 'noTertiary':
        this.isNoTertiarySelected = true;
        this.projectService.noTertiary = true;
        await this.saveHasTertiaryPackaging(false);
        await this.saveHasTertiaryPackaging(true);
        break;
    }
  }

  resetTabs(): void {
    this.isDefaultSelected = false;
    this.isSpecifySelected = false;
    this.isNoTertiarySelected = false;
    this.projectService.noTertiary = false;
  }

  createComponent(componentLevel: ComponentLevel): void {
    this.pkgComponentService
      .createPackagingComponent(
        this.projectService.selectedScenario,
        componentLevel
      )
      .catch((err) => console.error(err));
    this.scenarioOriginal = this.projectService.selectedScenario;
  }

  selectComponent(pkgComponentId: number): void {
    this.pkgComponentService.selectPkgComponent(pkgComponentId);
    void this.router.navigate(['../component', pkgComponentId], {
      relativeTo: this.route,
    });
  }

  isScenarioLimitReached(): boolean {
    const scenariosPerProject = this.globalService.limits.get(
      'scenariosPerProject'
    );
    assert(scenariosPerProject !== undefined);
    return this.project.scenarios.length >= scenariosPerProject;
  }

  isComponentLimitReached(level: number): boolean {
    const limit = this.guestService.isUserGuest()
      ? this.globalService.limitsGuestUser.get('componentsPerLevel')
      : this.globalService.limits.get('componentsPerLevel');
    assert(limit !== undefined);
    return this.scenarioOriginal.componentsOfLevel(level).length >= limit;
  }

  deleteComponent(component: PkgComponent): void {
    this.componentToBeDeleted = component;
    this.confirmModals
      .filter((item) => item.id == 'gen-delete-component')[0]
      .open();
  }

  async confirmOrAbortComponentDeletion(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'gen-delete-component')[0]
      .close();
    if (answer && this.componentToBeDeleted) {
      await this.pkgComponentService.deletePkgComponent(
        this.componentToBeDeleted
      );
      this.pkgComponentService.removeComponentFromSelectedScenario(
        this.componentToBeDeleted
      );
    }
    if (
      this.componentToBeDeleted?.id ==
      this.pkgComponentService.selectedPkgComponentId
    )
      this.goToScenario(this.scenarioOriginal.id);
  }

  switchHasSecondaryPackaging(): void {
    this.saveHasSecondaryPackaging(
      !this.scenarioOriginal.hasSecondaryPackaging
    ).catch((err) => console.error(err));
  }

  goToDefaultDataPDF(): void {
    void this.router.navigate(['/technical-appendix']);
  }

  protected readonly CustomValidators = CustomValidators;
}
