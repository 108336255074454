/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from '@/app/services/project.service';
import { GuestService } from '@/app/services/guest.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { GlobalService } from '@/app/services/global.service';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { from, Observable } from 'rxjs';
import { ScenarioResult } from '@/app/model/results/scenario-result';
import { DetailedResult } from '@/app/model/results/detailed-result';
import { ChartResult } from '@/app/model/results/chartResult';
import { plainToClassFromExist } from 'class-transformer';
import { SortingDirection } from 'src/app/model/search/sorting';
import { RechargeProject } from '@/app/model/packaging-model/project/recharge-project';
import { Indicator } from '@/app/model/results/indicator';
import { ResultsDisplayService } from '@/app/services/results-display.service';
import { min } from 'rxjs/operators';
import { ChartDataBuilderService } from '@/app/services/charts/chart-data-builder.service';
import { RechargeType } from '@/app/model/results/recharge-type';
import { BreakdownByMaterialModalComponent } from './breakdown-by-material-modal/breakdown-by-material-modal.component';
import { TypedSorting } from '@/app/model/search/typed-sorting';
import { SortService } from '@/app/services/sort.service';

//#region Methods which are defined in the .js files.

declare function buildOrUpdateChart(
  scenarioName: Array<string>,
  chart: string,
  results: Array<ChartResult>,
  number: number,
  xLabel: string,
  onClickCallback?: (evt: PointerEvent, activeElts: any[]) => void
): any;

declare function resetCanvas(canvasNames: Array<string>): any;

//#endregion

class ResultsPerIndicator {
  scenarioId!: number;
  scenarioName!: string;
  indicator1!: Indicator;
  resultIndicator1!: number;
  indicator2!: Indicator;
  resultIndicator2!: number;
  indicator3!: Indicator;
  resultIndicator3!: number;
}

@Component({
  selector: 'app-assess',
  templateUrl: './assess.component.html',
  styleUrls: ['./assess.component.css'],
})
export class AssessComponent implements OnInit, OnDestroy, AfterViewInit {
  SortingDirection = SortingDirection;
  isProjectIncomplete = false;
  computing = true;
  errorsWhileComputing = false;
  error500 = false;
  errors: { type: string; message: string } | undefined;
  firstSelectedIndic: Indicator | undefined;
  secondSelectedIndic: Indicator | undefined;
  thirdSelectedIndic: Indicator | undefined;
  selectedIndicators = new Array<Indicator | undefined>();
  selectedScenariosForAssess!: Array<any>;
  selectedScenariosForAssessCopy!: Array<any>;
  resultsPerIndicators: Array<ResultsPerIndicator> =
    new Array<ResultsPerIndicator>();
  scenarioDir: SortingDirection | undefined;
  indic1Dir: SortingDirection | undefined;
  indic2Dir: SortingDirection | undefined;
  indic3Dir: SortingDirection | undefined;
  indic1BestScenario!: number;
  indic2BestScenario!: number;
  indic3BestScenario!: number;
  deepDiveSelectedIndic!: Indicator;
  deepDiveRadioGroup = 'score';
  resultsPerIndicatorSorting!: TypedSorting<keyof ResultsPerIndicator>;

  @ViewChild(BreakdownByMaterialModalComponent)
  private readonly breakdownByMaterialModal!: BreakdownByMaterialModalComponent;

  get project(): SingleUseProject {
    if (this.guestService.isUserGuest() && this.guestService.guestUserProject) {
      return this.guestService.guestUserProject;
    } else return this.projectService.currentSingleUseProject;
  }

  get rechargeProject(): RechargeProject {
    return this.projectService.currentRechargeProject;
  }

  scenarioResults = new Array<ScenarioResult>();
  isSingleUse = false;
  scrHeight!: number;
  scrWidth!: number;
  get numberOfCharDisplayed(): number {
    return this.resultsDisplayService.numberOfCharDisplayed;
  }
  get heightOfIndicatorChart(): number {
    return this.resultsDisplayService.heightOfIndicatorChart;
  }
  get heightOfPackChart(): number {
    return this.resultsDisplayService.heightOfPackChart;
  }
  get heightOfLCAChart(): number {
    return this.resultsDisplayService.heightOfLCAChart;
  }
  get heightOfComponentChart(): number {
    return this.resultsDisplayService.heightOfComponentChart;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;

    this.resultsDisplayService.computeDisplayVariables(
      this.scrWidth,
      this.scrHeight
    );

    if (!this.computing && !this.errorsWhileComputing && !this.error500) {
      this.clearAllCanvas();
      this.buildOrUpdateAllCharts();
    }
  }

  constructor(
    private projectService: ProjectService,
    public globalService: GlobalService,
    public guestService: GuestService,
    private router: Router,
    private translationService: TranslationService,
    private resultsDisplayService: ResultsDisplayService,
    private chartDataBuilderService: ChartDataBuilderService,
    private sortService: SortService<ResultsPerIndicator>
  ) {
    this.getScreenSize();
    this.scenarioDir = SortingDirection.ASC;
    this.indic1Dir = undefined;
    this.indic2Dir = undefined;
    this.indic3Dir = undefined;
  }

  async ngOnInit(): Promise<void> {
    this.isSingleUse = this.router.url.includes('single-use');
    if (this.isSingleUse) {
      await this.projectService.reloadSingleUseProject(this.project.id);

      this.selectedScenariosForAssess = this.project.scenarios;
    } else {
      this.selectedScenariosForAssess = Array.of(this.rechargeProject);
    }
    this.projectService.selectedScenariosForAssess =
      this.selectedScenariosForAssess;
    if (!this.isSingleUse) this.projectIncomplete();
    this.initSelectedScenarioService();
    if (!this.isProjectIncomplete) this.computeResults();

    this.resultsPerIndicatorSorting = new TypedSorting<
      keyof ResultsPerIndicator
    >(SortingDirection.ASC, 'scenarioName');
  }

  ngOnDestroy(): void {
    this.clearAllCanvas();
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.computing && !this.errorsWhileComputing && !this.error500) {
      await this.delay(2);
      this.buildOrUpdateAllCharts();
    }
    this.projectService.dispatcher$.subscribe(() => {
      this.selectedScenariosForAssess =
        this.projectService.selectedScenariosForAssess;
      if (
        this.selectedScenariosForAssess.length > 0 &&
        this.areResultsAlreadyComputed(this.selectedScenariosForAssess) &&
        !this.computing &&
        !this.errorsWhileComputing &&
        !this.error500
      ) {
        const uncheckedScenarios = this.getUncheckedScenario();
        uncheckedScenarios?.forEach((s) =>
          resetCanvas(['componentChart_' + s.id.toString()])
        );

        this.selectedScenariosForAssessCopy = this.selectedScenariosForAssess;
        this.rebuildScenarioResultsPerSelectedIndicators();
        this.buildOrUpdateAllCharts();
      }
    });
  }

  getUncheckedScenario(): Scenario[] | null {
    const uncheckedScenarios: Scenario[] = [];
    this.project.scenarios.forEach((s: Scenario) => {
      if (!this.projectService.selectedScenariosForAssess.includes(s))
        uncheckedScenarios.push(s);
    });
    return uncheckedScenarios;
  }

  rebuildScenarioResultsPerSelectedIndicators(): void {
    this.resultsPerIndicators = new Array<ResultsPerIndicator>();
    this.selectedScenariosForAssessCopy.forEach((s) => {
      this.fillResultPerScenario(s);
    });

    from(this.resultsPerIndicators)
      .pipe(min((a, b) => (a.resultIndicator1 < b.resultIndicator1 ? -1 : 1)))
      .subscribe(
        (minIndic1) => (this.indic1BestScenario = minIndic1.scenarioId)
      );
    from(this.resultsPerIndicators)
      .pipe(min((a, b) => (a.resultIndicator2 < b.resultIndicator2 ? -1 : 1)))
      .subscribe(
        (minIndic2) => (this.indic2BestScenario = minIndic2.scenarioId)
      );
    from(this.resultsPerIndicators)
      .pipe(min((a, b) => (a.resultIndicator3 < b.resultIndicator3 ? -1 : 1)))
      .subscribe(
        (minIndic3) => (this.indic3BestScenario = minIndic3.scenarioId)
      );
  }

  areResultsAlreadyComputed(scenarios: Array<any>): boolean {
    const scenariosResultsFoundForScenarios = new Array<number>();
    if (this.scenarioResults != undefined && this.scenarioResults.length > 0) {
      scenarios.forEach((s) => {
        this.scenarioResults.forEach((sc) => {
          if (s.id == sc.id) {
            scenariosResultsFoundForScenarios.push(s.id);
          }
        });
      });
    }
    return (
      scenariosResultsFoundForScenarios.length >=
      this.selectedScenariosForAssess.length
    );
  }

  clearOverviewCanvas(): void {
    const projectId = this.isSingleUse
      ? this.project.id.toString()
      : this.rechargeProject.id.toString();
    const packagingKPIChartId = 'packagingKPIChart_' + projectId;
    resetCanvas([packagingKPIChartId]);
  }

  clearDeepDiveCanvas(): void {
    const projectId = this.isSingleUse
      ? this.project.id.toString()
      : this.rechargeProject.id.toString();
    const packBreakDownChartId = 'packagingBreakdownChart_' + projectId;
    const packagingLCAStepChartId = 'packagingLCAStepChart_' + projectId;
    const rechargeTypeChartId = 'rechargeBreakdownChart_' + projectId;
    const canvasNames = [packBreakDownChartId, packagingLCAStepChartId];
    const componentChartIds = new Map<number, string>();
    const scenariosForComponentCharts = this.getScenariosForComponentCharts();

    scenariosForComponentCharts.forEach((s) => {
      if (s != undefined) {
        componentChartIds.set(s.id, 'componentChart_' + s.id.toString());
        canvasNames.push('componentChart_' + s.id.toString());
      }
    });
    if (!this.isSingleUse) {
      canvasNames.push(rechargeTypeChartId);
    }

    resetCanvas(canvasNames);
  }

  clearAllCanvas(): void {
    this.clearOverviewCanvas();
    this.clearDeepDiveCanvas();
  }

  buildOrUpdateAllCharts(): void {
    this.buildOrUpdateOverviewGraph();
    this.buildOrUpdateDeepDiveGraphs();
  }

  truncateNamesIfNeeded(scenarioNames: Array<string>): string[] {
    const scenarioNamesTruncated: string[] = [];
    scenarioNames.forEach((s) => {
      if (s.length > this.numberOfCharDisplayed)
        scenarioNamesTruncated.push(s.slice(0, this.numberOfCharDisplayed - 1));
      else scenarioNamesTruncated.push(s);
    });

    return scenarioNamesTruncated;
  }

  trackByFn(item: Scenario): number {
    return item.id;
  }

  initSelectedScenarioService(): void {
    this.projectService.updateSelectedScenariosForAssess(
      this.selectedScenariosForAssess
    );
  }

  indicatorsAsList(): Array<Indicator> {
    const list: Indicator[] = [];
    if (this.scenarioResults != null && this.scenarioResults.length > 0)
      this.scenarioResults[0].results.perIndicatorImpacts.forEach((r) => {
        list.push(r.indicator);
      });
    list.sort((a, b) => (a.id > b.id ? 1 : -1));
    return list;
  }

  updateSelectedIndicators(): void {
    this.selectedIndicators = Array.of(
      this.firstSelectedIndic,
      this.secondSelectedIndic,
      this.thirdSelectedIndic
    );
    this.rebuildScenarioResultsPerSelectedIndicators();
    this.sortService.sortArray(
      this.resultsPerIndicators,
      this.resultsPerIndicatorSorting
    );
  }

  buildOrUpdateOverviewGraph(): void {
    const projectId = this.isSingleUse
      ? this.project.id.toString()
      : this.rechargeProject.id.toString();
    const packagingKPIChartId = 'packagingKPIChart_' + projectId;

    const selectedScenariosResults: DetailedResult[] =
      this.selectedScenariosForAssess.map(
        (s) => this.resultsForScenario(s.id)!
      );

    let scenarioNames = this.selectedScenariosForAssess.map((s) => s.name);
    scenarioNames = this.truncateNamesIfNeeded(scenarioNames);
    buildOrUpdateChart(
      scenarioNames,
      packagingKPIChartId,
      this.chartDataBuilderService.buildDataPerIndic(selectedScenariosResults),
      this.heightOfIndicatorChart +
        3 * this.projectService.selectedScenariosForAssess.length,
      selectedScenariosResults[0].totalSingleScore.indicator.displayUnit
    );
  }

  singleScoreOrIndicatorSelectionChanged(): void {
    this.buildOrUpdateDeepDiveGraphs();
  }

  buildOrUpdateDeepDiveGraphs(): void {
    const projectId = this.isSingleUse
      ? this.project.id.toString()
      : this.rechargeProject.id.toString();
    const packBreakDownChartId = 'packagingBreakdownChart_' + projectId;
    const packagingLCAStepChartId = 'packagingLCAStepChart_' + projectId;
    const rechargeTypeChartId = 'rechargeBreakdownChart_' + projectId;
    const componentChartIds = new Map();
    const scenariosForComponentCharts = this.getScenariosForComponentCharts();

    scenariosForComponentCharts.forEach((s) => {
      if (s != undefined)
        componentChartIds.set(s.id, 'componentChart_' + s.id.toString());
    });

    const graphUnit =
      this.deepDiveRadioGroup === 'score'
        ? this.translationService.getTranslationValueForLabel(
            'unit_pt_per_ml_short'
          )
        : this.deepDiveSelectedIndic.displayUnit;

    const selectedScenariosResults: DetailedResult[] =
      this.selectedScenariosForAssess.map(
        (s) => this.resultsForScenario(s.id)!
      );
    const singleScoreIndicator =
      selectedScenariosResults[0].totalSingleScore.indicator;
    const isSingleScoreSelected = this.deepDiveRadioGroup === 'score';
    let scenarioNames = this.selectedScenariosForAssess.map((s) => s.name);
    scenarioNames = this.truncateNamesIfNeeded(scenarioNames);

    if (!this.isSingleUse) {
      buildOrUpdateChart(
        scenarioNames,
        rechargeTypeChartId,
        this.chartDataBuilderService.buildRechargeChartData(
          selectedScenariosResults,
          isSingleScoreSelected,
          this.deepDiveSelectedIndic
        ),
        this.heightOfPackChart +
          3 * this.projectService.selectedScenariosForAssess.length,
        graphUnit
      );
    }

    buildOrUpdateChart(
      scenarioNames,
      packBreakDownChartId,
      this.chartDataBuilderService.buildPackChartData(
        selectedScenariosResults,
        isSingleScoreSelected,
        this.deepDiveSelectedIndic
      ),
      this.heightOfPackChart +
        3 * this.projectService.selectedScenariosForAssess.length,
      graphUnit
    );

    buildOrUpdateChart(
      scenarioNames,
      packagingLCAStepChartId,
      this.chartDataBuilderService.buildLCAChartData(
        selectedScenariosResults,
        isSingleScoreSelected,
        this.deepDiveSelectedIndic
      ),
      this.heightOfLCAChart +
        3 * this.projectService.selectedScenariosForAssess.length,
      graphUnit
    );

    scenariosForComponentCharts.forEach(async (s) => {
      if (s != undefined) {
        const scenarioResult = this.isSingleUse
          ? this.resultsForScenario(s.id)
          : this.resultsForScenario(this.rechargeProject.id);
        const onClickCallback = this.getOnClickCallback(
          isSingleScoreSelected,
          singleScoreIndicator
        );
        const graphHeight =
          this.heightOfComponentChart + s.components.length / 3;
        if (document.getElementById(componentChartIds.get(s.id)) === null) {
          await this.delay(2);
        }
        buildOrUpdateChart(
          [],
          componentChartIds.get(s.id),
          this.chartDataBuilderService.buildComponentChartData(
            scenarioResult!,
            isSingleScoreSelected,
            this.deepDiveSelectedIndic,
            !this.isSingleUse
              ? s.id ===
                this.rechargeProject.daughterProject.referenceScenario?.id
                ? RechargeType.DAUGHTER
                : RechargeType.MOTHER
              : null
          ),
          graphHeight,
          graphUnit,
          onClickCallback
        );
      }
    });
  }

  getOnClickCallback(
    isSingleScoreSelected: boolean,
    singleScoreIndicator: Indicator
  ): (evt: PointerEvent, activeElts: any[]) => void {
    return (evt: PointerEvent, activeElts: any[]) => {
      if (activeElts.length) {
        const clickedChart = activeElts[0]._chart;
        const clickedBar = clickedChart.getElementAtEvent(evt)[0];
        if (clickedBar != undefined) {
          const clickedComponent: ChartResult =
            clickedChart.data.datasets[clickedBar._datasetIndex];
          if (clickedComponent.elementId! !== 0) {
            this.breakdownByMaterialModal.open(
              clickedComponent.elementId!,
              clickedComponent.label,
              isSingleScoreSelected,
              isSingleScoreSelected
                ? singleScoreIndicator
                : this.deepDiveSelectedIndic,
              this.isSingleUse ? undefined : this.rechargeProject.id
            );
          }
        }
      }
    };
  }

  compute(): Observable<ScenarioResult[] | ScenarioResult> {
    if (this.isSingleUse)
      if (this.guestService.isUserGuest())
        return this.projectService.computeGUSingleUseScenarioResults();
      else
        return this.projectService.computeSingleUseScenariosResults(
          this.project.id
        );
    else
      return this.projectService.computeRechargeProjectResults(
        this.rechargeProject.id
      );
  }

  computeResults(): void {
    this.computing = true;
    this.compute()
      .toPromise()
      .then(async (r) => {
        if (this.isSingleUse)
          this.scenarioResults = plainToClassFromExist(
            new Array<ScenarioResult>(),
            r
          );
        else
          this.scenarioResults = Array.of(
            plainToClassFromExist(new ScenarioResult(), r)
          );

        this.computing = false;
        if (!this.computing && !this.errorsWhileComputing && !this.error500) {
          await this.delay(2);
          this.selectedScenariosForAssessCopy = Array.from(
            this.selectedScenariosForAssess
          );
          this.buildOrUpdateAllCharts();
        }
        this.initSelectedIndicators();

        if (this.scenarioResults != undefined) this.updateSelectedIndicators();
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: {
    status: number;
    error: undefined | { type: string; message: string };
  }): null {
    if (err.status == 500) {
      this.error500 = true;
    } else {
      this.errorsWhileComputing = true;
      this.errors = err.error;
    }
    this.computing = false;
    return null;
  }

  initSelectedIndicators(): void {
    this.firstSelectedIndic = this.indicatorsAsList().find((i) => i.id == 1); //Climate change
    this.secondSelectedIndic = this.indicatorsAsList().find((i) => i.id == 13); //Land use
    this.thirdSelectedIndic = this.indicatorsAsList().find((i) => i.id == 14); //Ressource use fossil
    this.deepDiveSelectedIndic = this.indicatorsAsList().find(
      (i) => i.id == 1
    )!; //Climate change
  }

  resultsForScenario(scenarioId: number): DetailedResult | undefined {
    return this.scenarioResults.find((result) => result.id == scenarioId)
      ?.results;
  }

  resultsPerIndicatorPerScenario(
    scenarioId: number,
    label: string
  ): number | undefined {
    return this.resultsForScenario(scenarioId)?.perIndicatorImpacts.find(
      (i) => i.indicator.label == label
    )?.result;
  }

  fillResultPerScenario(s: Scenario): void {
    const resultPerScenario = new ResultsPerIndicator();
    resultPerScenario.scenarioName = s.name;
    resultPerScenario.scenarioId = s.id;
    if (this.firstSelectedIndic != undefined) {
      resultPerScenario.indicator1 = this.firstSelectedIndic;
      // @ts-ignore
      resultPerScenario.resultIndicator1 = this.scenarioResults
        .find((sr) => sr.id == s.id)
        .results.perIndicatorImpacts.find(
          (i) => i.indicator.value == this.firstSelectedIndic?.value
        ).result;
    }

    if (this.secondSelectedIndic != undefined) {
      resultPerScenario.indicator2 = this.secondSelectedIndic;
      // @ts-ignore
      resultPerScenario.resultIndicator2 = this.scenarioResults
        .find((sr) => sr.id == s.id)
        .results.perIndicatorImpacts.find(
          (i) => i.indicator.value == this.secondSelectedIndic?.value
        ).result;
    }

    if (this.thirdSelectedIndic != undefined) {
      resultPerScenario.indicator3 = this.thirdSelectedIndic;
      // @ts-ignore
      resultPerScenario.resultIndicator3 = this.scenarioResults
        .find((sr) => sr.id == s.id)
        .results.perIndicatorImpacts.find(
          (i) => i.indicator.value == this.thirdSelectedIndic?.value
        ).result;
    }

    this.resultsPerIndicators.push(resultPerScenario);
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getIndicatorDifference(scenarioId: number, indicator: Indicator): number {
    const scenarioIndicValue = this.resultsPerIndicatorPerScenario(
      scenarioId,
      indicator.label
    )!;
    const refScenarioIndicValue = this.resultsPerIndicatorPerScenario(
      this.project.referenceScenario!.id,
      indicator.label
    )!;
    return (
      100 *
      ((scenarioIndicValue - refScenarioIndicValue) / refScenarioIndicValue)
    );
  }

  launchSort(): void {
    this.sortService.sortArray(
      this.resultsPerIndicators,
      this.resultsPerIndicatorSorting
    );
  }

  projectIncomplete(): void {
    if (!this.rechargeProject.isComplete()) {
      this.isProjectIncomplete = true;
    }
  }

  goToExplanationPage(): void {
    void this.router.navigate(['/explanations']);
  }

  hideOrShow(scenarioId: number | undefined): void {
    const scenarioGraph =
      scenarioId != undefined
        ? document.getElementById('collapse_' + scenarioId.toString())
        : null;
    if (scenarioGraph?.classList.contains('hide'))
      scenarioGraph.classList.remove('hide');
    else scenarioGraph?.classList.add('hide');
  }

  isGraphShown(scenarioId: number | undefined): boolean {
    const scenarioGraph =
      scenarioId != undefined
        ? document.getElementById('collapse_' + scenarioId.toString())
        : null;
    return !scenarioGraph?.classList.contains('hide');
  }

  getScenariosForComponentCharts(): Array<Scenario | undefined> {
    return this.isSingleUse
      ? this.selectedScenariosForAssess
      : [
          this.rechargeProject.motherProject.referenceScenario,
          this.rechargeProject.daughterProject.referenceScenario,
        ];
  }

  getFormattedResult(result: number): string {
    return result < 0.1 ? result.toExponential(2) : result.toFixed(2);
  }
}
