<ng-template #breakdownByMaterial let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ componentName }}
    </h4>
  </div>
  <div class="modal-body">
    <div *ngIf="guestService.isUserGuest(); else graphDiv">
      <p>{{ "interface_pro_version_tooltip" | translationPipe }}</p>
    </div>
    <ng-template #graphDiv class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="graph_container">
            <canvas
              id="materialBreakdownForComponentChart_{{ componentId }}"
            ></canvas>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss()"
    >
      Close
    </button>
  </div>
</ng-template>
