import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UserManagementModalComponent } from '@/app/pages/user-management/user-management-modal/user-management-modal.component';
import { ConfirmModalComponent } from '@/app/pages/confirm-modal/confirm-modal.component';
import { Sorting, SortingDirection } from '@/app/model/search/sorting';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Page } from '@/app/model/pagination/page';
import { LoginService } from '@/app/services/login/login.service';
import { User } from '@/app/model/user/user';
import { UserService } from '@/app/services/user.service';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeaderDirective } from '@/app/directive/sortable.directive';
import { FeatureService } from '@/app/services/featureService/feature.service';
import { FeatureStatus } from '@/app/services/featureService/feature-status';
import { UsualFeaturesAndRules } from '@/app/services/featureService/usual-features-and-rules';
import { DeleteUserModalComponent } from '@/app/pages/user-management/delete-user-modal/delete-user-modal.component';
import { OpenProjectModalComponent } from '@/app/pages/landing/open-project-modal/open-project-modal.component';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { Pager } from '@/app/utils/pager';

@Component({
  selector: 'app-member-user',
  templateUrl: './member-user.component.html',
  styleUrls: ['./member-user.component.css'],
})
export class MemberUserComponent implements OnInit {
  @ViewChild(UserManagementModalComponent)
  private userManagementModalComponent!: UserManagementModalComponent;
  @ViewChild(DeleteUserModalComponent)
  private deleteUserModalComponent!: DeleteUserModalComponent;
  @ViewChild(OpenProjectModalComponent)
  private openProjectModalComponent!: OpenProjectModalComponent;
  @ViewChildren(ConfirmModalComponent)
  private confirmModals!: QueryList<ConfirmModalComponent>;

  users: Array<User> = [];
  page = 0;
  searchFilter = '';
  sorting = new Sorting(SortingDirection.ASC, 'firstname');

  pager!: Pager;
  search!: UntypedFormControl;
  selectedUser: User | undefined;
  componentReady = true;

  @ViewChildren(NgbdSortableHeaderDirective)
  headers!: QueryList<NgbdSortableHeaderDirective>;

  constructor(
    private loginService: LoginService,
    public translationService: TranslationService,
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal,
    private featureService: FeatureService
  ) {}

  openModalToCreateUser(): void {
    this.userManagementModalComponent.open();
  }

  isSuperAdmin = (): boolean => this.loginService.isSuperAdmin();
  isAdminOrSuperAdmin = (): boolean => this.loginService.isAdminOrSuperAdmin();

  ngOnInit(): void {
    this.featureService.dispatcher$.subscribe((fs: FeatureStatus) => {
      if (fs.feature == UsualFeaturesAndRules.LOGGED_FEATURE && fs.isActive) {
        this.onComponentReady();
      }
    });

    if (this.featureService.isActive(UsualFeaturesAndRules.LOGGED_FEATURE)) {
      this.onComponentReady();
    }
  }

  onComponentReady(): void {
    this.search = new UntypedFormControl();
    this.componentReady = true;
    this.onSearchChange();

    if (!this.loginService.isAdminOrSuperAdmin()) {
      void this.router.navigate(['/error', 'not-found']);
    } else {
      this.resetPagination();
      this.launchSearch();
    }
  }

  private onSearchChange() {
    this.search.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => this.launchSearch());
  }

  resetPagination(): void {
    this.page = 0;
    this.sorting = new Sorting(SortingDirection.ASC, 'firstname');
    this.searchFilter = '';
  }

  setPage(page: number): void {
    this.page = page - 1;
    this.launchSearch();
  }

  openModalToEditUser(user: User): void {
    this.userManagementModalComponent.open(user);
  }

  launchSearch(): void {
    const filterCriteria: Array<FilterCriterion> = [];

    if (this.search.value) {
      filterCriteria.push(new FilterCriterion('search', this.search.value));
    }

    this.userService
      .getUsers(this.page, filterCriteria, this.sorting)
      .then((page: Page<User>) => {
        this.users = page.content;
        this.pager = Pager.getPager(
          page.totalElements,
          page.number + 1,
          page.size
        );
      })
      .catch((err) => console.error(err));
  }

  disableUser(user: User): void {
    this.selectedUser = user;
    this.confirmModals.filter((item) => item.id == 'user-deactivate')[0].open();
  }

  activateUser(user: User): void {
    this.selectedUser = user;
    this.confirmModals.filter((item) => item.id == 'user-activate')[0].open();
  }

  deleteUser(user: User): void {
    this.selectedUser = user;
    this.deleteUserModalComponent.open(this.selectedUser, true);
  }

  transferUserProjects(user: User): void {
    this.selectedUser = user;
    this.deleteUserModalComponent.open(this.selectedUser, false);
  }

  openModalProject(user: User): void {
    this.openProjectModalComponent.open(user);
  }

  getUserFullname(): Map<any, any> {
    return new Map().set(
      '$1',
      this.selectedUser ? this.selectedUser.displayedName : ''
    );
  }

  confirmEnableDisableUser(confirm: boolean): void {
    this.modalService.dismissAll();

    if (this.selectedUser && confirm) {
      this.selectedUser.enabled = !this.selectedUser.enabled;
      this.userService
        .adminEditMemberUser(this.selectedUser)
        .catch((err) => console.error(err));
    }
  }

  getCurrentUser(): User | null {
    return this.loginService.currentUser;
  }

  displayEditUser(user: User): boolean {
    return !this.isCurrentUser(user);
  }

  displayProjectUser(user: User): boolean {
    return this.getCurrentUser()?.company.id == user.company.id;
  }

  displayDisableUser(user: User): boolean {
    return this.displayEditUser(user) && user.enabled;
  }

  displayEnabledUser(user: User): boolean {
    return this.displayEditUser(user) && !user.enabled;
  }

  displayDeleteUser(user: User): boolean {
    return this.displayEditUser(user) && !user.enabled;
  }

  isCurrentUser(user: User): boolean {
    return user.id == this.loginService.currentUser?.id;
  }

  async exportMemberStats(): Promise<void> {
    const locale = this.getCurrentUser()?.locale;
    await this.userService.superAdminGetMemberUserStatistics(locale);
  }
}
